import React from "react";
import "./index.css";

const Pricing = () => {
  const plans = [
    {
      title: "Free Edition",
      price: "Free",
      description: "Perfect for individuals and small teams",
      features: [
        "One workspace user",
        "IoT, PostgreSQL, CSV integration",
        "5 chats per day for user",
        "Unlimited analytics",
        "Email support",
        "Mobile app (Coming Soon)",
      ],
      buttonLabel: "Join Waitlist",
      highlight: "Forever Free",
      isEnterprise: false,
      path:'http://www.disai.otamat.com/register'
    },
    {
      title: "Enterprise Edition",
      price: "Let's Talk",
      description: "Tailored for medium and large organizations",
      features: [
        "Readymade decisions and actions",
        "Unlimited usage",
        "Unlimited KPIs",
        "Customized AI models",
        "Advanced GenAI and agentic AI features",
        "White-label options",
        "Unlimited data sources",
        "24/7 support",
        "Advanced security features",
      ],
      buttonLabel: "Contact Us",
      highlight: "Custom Solutions",
      isEnterprise: true,
      path:'/contact'
    },
  ];


  const handleNavigate = (path) => {
    window.open(path, '_blank');
  };

  return (
    <div className="pricing-container">
      <div className="inner-container">
        <h1 className="pricing-header">Choose Your Perfect Plan</h1>
        <p className="pricing-subheader">
          Get exclusive access to Othor AI. Pick the plan that best suits your
          needs and elevate your business with powerful tools.
        </p>
        <div className="pricing-grid">
          {plans.map((plan, index) => (
            <div
              key={index}
              className={`pricing-card ${
                plan.isEnterprise ? "enterprise-card" : "free-card"
              }`}
            >
              <h3 className="pricing-title">{plan.title}</h3>
              <h4 className="pricing-highlight">{plan.highlight}</h4>
              <h2 className="pricing-price">{plan.price}</h2>
              <p className="pricing-description">{plan.description}</p>
              <ul className="pricing-features">
                {plan.features.map((feature, i) => (
                  <li key={i} className="feature-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      width="20"
                      height="20"
                    >
                      <path d="M9 19l-7-7 1.414-1.414L9 16.172l11.586-11.586L22 6z" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="pricing-button" onClick={()=>handleNavigate(plan.path)}>{plan.buttonLabel}</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
