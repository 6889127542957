import React from "react";
import "./index.css";
import rotate from '../../../assets/rotate.svg'
export const Third = ({handleNavigate}) => {
  return (
    <div className="third-container">
      <div className="content">
        <div className="icon">
          <img src={rotate} alt="Icon" />
        </div>
        <h1>
        Optimize  <span className="highlight">Decision</span> Making,{" "}
          <span className="highlight">Efficiency</span>
        </h1>
        <p>
          Stay Ahead in Business -- Empower Yourself with Otamat AI
        </p>
        <button className="cta-button" onClick={()=>handleNavigate()}>Get Early Access!</button>
      </div>
    </div>
  );
};
