import Header from "../Header";
import "./index.css";
import d1 from "../../assets/d1.png";
import { Second } from "./second";
import { Third } from "./third";
import { Link } from "react-router-dom";

export const Home = () => {
  const handleNavigate = () => {
    window.open("http://www.disai.otamat.com/register", "_blank");
  };
  return (
    <div>
      <Header />
      <div className="hero-section customfont">
        <div className="tagline customfont">
          <span className="badge">
            🚀 Data to Actions in Less than 3 minutes
          </span>
        </div>
        <h1 className="main-heading">Make Great Actions Every Day</h1>
        <p className="description">
          Decision-based Self-Service data-driven GenAI Platform" combines
          elements of decision support, self-service accessibility, advanced
          analytics, data-driven insights, and Generative Artificial
          intelligence to empower users to make informed decisions and optimize
          processes across various domains.
        </p>
        <div className="buttons customfont" onClick={handleNavigate}>
          <button className="start-btn">Get Started Free</button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            width: "100%",
          }}
        >
          <img
            src={d1}
            alt="Dashboard Preview"
            style={{
              width: "100%",
              maxWidth: "1000px",
              borderRadius: "8px",
              height: "500px",
            }}
          />
        </div>
      </div>
      <Second handleNavigate={handleNavigate} />
      <Third handleNavigate={handleNavigate} />
    </div>
  );
};
