import React from "react";
import "./index.css"; // Ensure you style it with SCSS or CSS as per your project
import profile from '../../assets/profile.png'
export const Contact = () => {
  return (
    <div className="contact-container">
      <div className="profile">
        <img
          src={profile}
          alt="Profile"
          className="profile-image"
        />
        <h2 className="profile-name">Vijay Gunti</h2>
        <p className="profile-role">Founder &amp; CEO - Othor AI</p>
      </div>

      <div className="meeting-options">
        <a
          href="https://calendly.com/your-30min-link" // Replace with your actual 30-minute meeting link
          target="_blank"
          rel="noopener noreferrer"
          className="meeting-option"
        >
          <h3>30 Min Meeting</h3>
          <span className="meeting-duration">
            <i className="clock-icon" /> 30m
          </span>
        </a>

        <a
          href="https://calendly.com/your-15min-link" // Replace with your actual 15-minute meeting link
          target="_blank"
          rel="noopener noreferrer"
          className="meeting-option"
        >
          <h3>15 Min Meeting</h3>
          <span className="meeting-duration">
            <i className="clock-icon" /> 15m
          </span>
        </a>
      </div>
    </div>
  );
};
