import { Link } from 'react-router-dom';
import logo512 from '../../../src/logo512.png';
import './index.css';

const Header = () => (
  <nav className="nav-element-main customfont">
    <img src={logo512} className="logo-element" alt="logo" />
    <div className="nav-elements">
      <Link to="/" className="link-element">
        <p className="nav-element">Home</p>
      </Link>
      <Link to="/pricing" className="link-element">
        <p className="nav-element">Pricing</p>
      </Link>

    </div>
    <Link to="/contact" className="link-element">
        <button type="button" className="book-demo-button customfont">
          Book Demo
        </button>
      </Link>
  </nav>
);

export default Header;
