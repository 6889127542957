import {Route , Switch} from 'react-router-dom'
import './App.css';
import {Home} from './components/Home'
import Pricing from './components/Pricing';
import { Contact } from './components/contact';

const App = () =>(
  <Switch>
    <Route exact path='/' component={Home} />
    <Route exact path='/home' component={Home}/>
    <Route exact path='/pricing' component={Pricing} />
    <Route exact path='/contact' component={Contact} />
  </Switch>
)


export default App;
